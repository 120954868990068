import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <div className="flex justify-center items-center pb-5">
          {
            rpdata?.gmb?.link.length > 1 ?
              <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
              </a>
              : null
        }
        </div>
        <h2 className="text-center pb-5">Find Us</h2>
        <div className=" flex justify-center">
          <div className="flex flex-wrap justify-center items-center w-4/5 mx-auto">
            {
              rpdata?.dbSocialMedia?.directorios[0].url.length > 0 ?
                rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                  return (
                    <div
                      className="text-center md:w-[220px] w-full h-[100px] flex justify-center self-center items-center p-2"
                      key={index}
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
              return (
                <div
                className="text-center md:w-[220px] w-full h-[100px] flex justify-center self-center items-center p-2"
                  key={index}
                >
                  <a href={item.url} target="_blank" rel='noopener noreferrer'>
                    <img src={item.image} alt="Not Found" width={'250px'} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directories;