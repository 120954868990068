/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FaMapMarkedAlt } from "react-icons/fa";

const Map = (props) => {
 const { rpdata } = useContext(GlobalDataContext);


 const sites = [
  "Newark, CA",
  "San Jose, CA",
  "Milpitas, CA",
  "Fremont, CA",
  "Los Gatos, CA",
  "Union City, CA",
  "Hayward, CA",
  "Oakland, CA",
  "San Leandro, CA",
  "Castro Valley, CA",
  "Santa Clara, CA",
  "Sunnyvale, CA",
  "Palo Alto, CA",
  "Mountain View, CA",
  "Redwood City, CA",
  "San Mateo, CA",
  "San Francisco, CA",
  "Dublin, CA",
 ]

  return (
    <div>
      <div className="w-full mt-[60px]">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}</h2>
        <div className="flex flex-wrap justify-center w-4/5 mx-auto">
          {
            sites.map((site, index) => {
              return (
                  <ul key={index}>
                    <li className=" flex mx-3 py-1">
                      <FaMapMarkedAlt className="flex self-center mr-1 color-2"/>
                      {site}
                      </li>
                  </ul>
              )
            })
          }
        </div>
        <iframe src={rpdata?.dbPrincipal?.location?.[0].url} className="w-full h-[500px]"/>
      </div>
    </div>
  )
}

export default Map
